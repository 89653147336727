import React, { Component } from 'react'
import { Redirect } from '@reach/router'

class HomePage extends Component {
  render() {
    return <Redirect noThrow to="/vote22" />
  }
}

export default HomePage
